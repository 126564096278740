.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
body{
  font-family: 'Open Sans' !important;
  overflow-x: hidden;
}

h1,h2,h3{
  font-family: 'Open Sans' !important;
}

/* Your Journey css */
 * {
   box-sizing: border-box;
 }

 .timeline {
   position: relative;
   width: 100%;

   margin: 0 auto;
   padding: 15px 0;
 }

 .timeline::after {
   content: '';
   position: absolute;
   width: 3px;
   background: grey;
   top: 0;
   bottom: 0;
   left: 50%;
   margin-left: -5px;
 }

 /* The circles on the timeline */
 .tl_circle::after {
   content: '';
   position: absolute;
   width: 20px;
   height: 20px;
   background-color: white;
   border: 3px solid gray;
   top: 0px;
   border-radius: 50%;
   z-index: 1;
   left: 20px;
 }

 .bg-img{
  /* background-image: url(./Images/landing-page-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed; */
   background-color: #cee2fa;
 }

.signUp {
  background-image: url(./Images/landing-page-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  /* background-color: #d8ebfb ; */
}
.box{
  margin:auto;
  padding:0;
  font-family: 'Open Sans', sans-serif !important;
  background-color: #ffffff;
  /* width:1000px;
  height:800; */
   width: 600px;
   padding: 20px;
   /* position: absolute; */
   /* top: 50%;
   left: 50%; */
   text-align: center;
   border-radius: 12px;
   
 }
 .bg-img{
background-image: url(./Images/landing-page-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
   /* background-color: #cee2fa; */
 }
 /* .box{
  margin:0;
  padding:0;
  font-family:sans-serif;
  background-color: #ffffff;
   width: 600px;
   padding: 20px; */
   /* position: absolute; */
   /* top: 50%;
   left: 50%; */
   /* text-align: center;
   border-radius: 12px;; */
 /* } */
 .form-control{
  /* border:10px; */
  /* border-color: #a00a48 !important; */
  display:block;
  margin: 10px auto;
  text-align: left;
 }

 .signupBtn{
   border-radius: 50px !important;
   height: 50px;
   display: block;
   border:2px;
   margin: 20px auto;
   background-color: #007eff !important;
   color: white !important;


 }
 .signupBtn:hover{
   background-color: #fdd70d !important;
   color:#333333 !important;
 }

 .userBtn{
   border:1px solid;
   border-color: #006fde;
   background-color:  #cee3f9;
   color:#000000 ;
   margin: auto 20px;
   padding: 10px 30px;
   border-radius: 30px;
   display:inline;
 }
 .therapistBtn{
   border: 1px solid;
   border-color: #fdd70d;
   color: #333333;
   background-color:#fdf3c0 ;
   margin: auto 20px;
   padding: 10px 30px;
   border-radius: 30px;
   display:inline;
 }

.userBtn:focus{
  background-color: #006fde;
  color:white;
}
.userBtn:hover{
  background-color: #006fde;
  color:white;
}
.therapistBtn:focus{
  background-color: #ffd70d;
  color:#333333

}
.therapistBtn:hover{
  background-color: #ffd70d;
  color:#333333

}

/* .btn{
  border-radius: 30px !important;
  background-color:#006fde !important;
  color:#ffffff !important;
} */

ul.sidebar > li a {
margin-left: 0px;
  font-family:sans-serif;
  /* background-color: blue; */
  /* width:250px; */
   padding: 10px 35px;
   
  color:#333 !important;

}
ul.sidebar > li a:hover {
  margin-left: 0px;
    font-family:sans-serif;
    background-color: #e7e7e7;
    /* width:250px; */
     padding: 10px 35px;
    color:#333 !important;
    cursor:pointer;
  
  }

 .bg-therapist{
    background-color: #cee2fa;
 }
 
 .boxTherpist{
  /*margin: 60px auto 40px auto; */
  margin:auto;
  font-family: 'Open Sans', sans-serif !important;
  padding:20px;
  /*width:1200px;
  height:1000px;*/
  width: 600px;
  background-color: white;
  text-align: center;
  border-radius: 12px;
 }
 
 .playAudioBtn {
  font-size: 80px;
  color: #004d99;
}
.audioControlBtn {
  font-size: 50px;
  color: grey;
  margin: 0;
  padding: 0;
}
.controlSeconds{
  line-height: 10px;
  color: grey;
}
.btnStandard {
  font-family: 'Open Sans', sans-serif !important;
  font-size: 14px !important;
  max-width: 400px;
  max-height: 70px;
  /*min-height: 65px;*/
  margin: auto;
  padding: 10px;
  border-radius: 30px !important;
  border-color: #006fde !important;
  /*background-color: white;*/
}
.btnStandardMini {
  font-family: 'Open Sans', sans-serif !important;
  font-size: 16px !important;
  max-width: 200px;
  min-width: 120px;
  /*max-height: 70px;*/
  /*min-height: 65px;*/
  margin: auto;
  padding: 10px;
  border-radius: 30px !important;
  border-color: #006fde !important;
  /*background-color: white;*/
}